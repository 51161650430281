<template lang="pug">
.dk.Container
  .contain.Content
    .box-error
      .pt-5.m-5
        AlertOutline(:size="50").alert
      p(style="color: gray;").text-center.mb-3 Nuestro sistema de seguridad ha detectado fallas de seguridad en este navegador.
      p(style="color: gray;").text-center.mb-3 Porfavor intenta nuevamente desde un navegador distinto
      p(style="color: gray;").text-center.mt-2.pt-5 YA PUEDES CERRAR ESTA VENTANA
  .Footer
</template>

<script>
import { mapState } from "vuex";
import AlertOutline from "mdi-vue/AlertOutline";

export default {
  components: {
    AlertOutline
  },

  // created() {
  //   if (this.isAtril) this.$store.dispatch("setTimer");
  // },

  computed: {
    ...mapState({
      isAtril: state => state.virtualrowFSFB.isAtril
    })
  }
};
</script>

<style>
.alert {
  fill: #f00;
  color: #f00;
  text-align: center;
  max-width: 147px;
  display: block;
  margin: auto;
}

.dk {
  max-width: 350px;
  display: block;
  margin: auto;
}
</style>

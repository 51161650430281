<template lang="pug">
.LoadingFSFB
  .lds-ring
    div
    div
    div
    div
  h3.fonterH3 Escaneando...
</template>

<script>
export default {
  name: "LoadingFSFB"
};
</script>

<style scoped>
.LoadingFSFB {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;
  position: fixed;
  z-index: 802;
  top: 96.38px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: #ffffffba;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 164px;
  height: 164px;
  margin: 8px;
  border: 16px solid #001598;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #001598 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

h3.fonterH3 {
  margin: 0;
  margin-top: 2rem;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

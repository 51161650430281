<template lang="pug">
.Pay.Container
  .Content
    div
      article.text-center
        p.text-center(
          :class="{'.mt-4': i === 0, '.mt-2': i > 0, '.mb-3': (i + 1) >= $store.state.virtualrow.currentValidation.length }"
          v-for="(paso, i) in $store.state.virtualrow.currentValidation"
        ) {{ paso.DescripcionPaso }}
      slot
  .Footer
</template>

<script>
import Header from "./components/Header";

export default {
  name: "Pay",
  components: {
    Header
  }
};
</script>

<style scoped>
.Pay {
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  table-layout: fixed;
  position: relative;
}

.Pay > div {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
}
</style>

<template lang="pug">
.Normativity.Container(@click="actionActive" :style="isAtril ? 'margin: auto; width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 70px);' : 'width: 100%; font-size: 1.3rem;'")
  .Content
    div(:style="isAtril ? 'position: relative; margin: auto; margin-top: 72px; margin-bottom: 33px; height: 37px; max-width: 701px;' : 'position: relative; height: 48px; margin-top: 20px;'")
      span.text-center.mt-2.h.dk-pos-dk(:class="isAtril ? 'h1 isAtril' : ''" :style="isAtril && 'font-weight: bold !important; font-size: 2.8rem;'") {{ siteIsOffice ? "Oficina" : "Centro Médico" }} {{ siteName }}
    article.Article(:style="!isAtril ? 'color: #545454; margin-top: 40px;' : 'color: #545454; margin-top: 40px; max-width: 460px;'")
      p.text-center.mb-2.mt-2.mr-2.ml-2 Si eres una persona:
      ul(style=`
        list-style: none;
        text-align: start;
        margin: 0;
      `).mr-2.ml-2
        li - Mayor de 62 años
        li - En estado de embarazo
        li - En condición de discapacidad
      p.mb-2.mt-2.text-left.mr-2.ml-2 tienes derecho a:
      div(style=`
        padding-bottom: 20px;
        margin-top: 1.3rem;
      `)
        b-button(:style="colorComputed" variant="bluecolmedica" class="text-center" size="sm" type="button" @click="confirmedAction" block)
          span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Tomar turno preferencial
        b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goBack" block :style="`margin-top: 20px; ${colorComputed}`")
          span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Volver al menú principal
      p(style=`
        line-height: 1;
        font-style: italic;
        margin-top: 0.1rem;
        text-align: center;
      `).mr-2.ml-2
        small(:style="isAtril ? 'font-size: 73%; max-width: 345px; text-align: center; display: block; margin: 0.5rem auto; margin-bottom: 2rem;' : ''")
          | Entre todos debemos proteger, promover, respetar y defender los derechos de esta población (Circular única 049 de la supersalud)
  .buttons.Footer(:style="colorSecondaryComputed")
    .d-flex.justify-content-between
      b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goBack" :style="isAtril ? `font-size: 1.5rem !important; ${colorComputed}` : colorComputed")
        Back(size="24px")
        span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Atrás
</template>

<script>
import { mapActions, mapState } from "vuex";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import WheelBarrow from "mdi-vue/WheelchairAccessibility";

export default {
  name: "Normativity",

  components: {
    Header,
    Back,
    WheelBarrow
  },

  created() {
    // if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      previousView: state => state.virtualrowFSFB.previousView,
      isAtril: state => state.virtualrowFSFB.isAtril,
      currentSite: state => state.virtualrowFSFB.currentSite,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      secondColorBlue: state => state.virtualrowFSFB.secondColorBlue
    }),

    siteName() {
      return this.currentSite?.name;
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      goBack: "virtualrowFSFB/goBack",
      turnCall: "virtualrowFSFB/turnCall",
      confirmedAction: "virtualrowFSFB/confirmedAction"
    }),

    dontCallToEmergency() {
      this.priority = false;
    },

    actionActive() {
      // if (this.isAtril) this.$store.dispatch("setTimer");
    }
  }
};
</script>

<style>
.Normativity {
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.Normativity > div {
  display: table-cell;
  vertical-align: middle;
}

.Article {
  max-width: 300px;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

.blue-dk {
  color: #00599d;
}

.image {
  width: 100%;
  object-fit: cover;
}

.dk-pos-dk.isAtril {
  top: -90%;
}
.dk-pos-dk {
  position: absolute;
  left: 0px;
  transition: 1s;
  right: 0px;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@media screen and (max-width: 420px) {
  .image {
    width: 50%;
  }
}
</style>

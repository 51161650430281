<template lang="pug">
.Header(:class="[topClass]" :style="topStyle" v-if="view")
  button(
    @click="back"
    v-if="view !== 'QR' && view !== 'Turn' && view != 'Home'"
  ).button-without-styles.d-inline-block.align-middle
    p.goback.my-0
      svg(width='13' height='21' viewbox='0 0 13 21' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.48675 20.5287L0.469899 11.6232C0.168932 11.3247 0 10.9209 0 10.5C0 10.0791 0.168932 9.67534 0.469899 9.37685L9.48675 0.471272C9.71277 0.246595 10.0015 0.0933667 10.3162 0.0311238C10.6309 -0.0311191 10.9573 0.000443073 11.2537 0.121786C11.5502 0.24313 11.8032 0.448756 11.9807 0.71245C12.1581 0.976144 12.2518 1.28596 12.25 1.60239V19.3976C12.2518 19.714 12.1581 20.0239 11.9807 20.2876C11.8032 20.5512 11.5502 20.7569 11.2537 20.8782C10.9573 20.9996 10.6309 21.0311 10.3162 20.9689C10.0015 20.9066 9.71277 20.7534 9.48675 20.5287Z' fill='#6BBBC5')
      span.ml-2.d-inline-block.align-middle Atrás
  span(v-else)
  .imageContainerHeader(v-if="view !== 'QR'")
    img(:class="[logoClass]" :style="logoStyle" :src="Logo" v-openlog)
  .imageContainerHeader(v-else)
    img(:class="[logoClass]" :style="logoStyle" :src="Logo" v-openlog).p-0
  span
</template>
<script>
import LogoColmedica from "@/assets/colmedica_logo.png";
import { mapActions, mapState } from "vuex";

export default {
  name: "Header",

  props: {
    topClass: {
      type: String,
      default: "col-12 header"
    },
    topStyle: {
      default: () => ({})
    },
    logoClass: {
      type: String,
      default: "client_logo"
    },
    logoStyle: {
      default: () => ({})
    }
  },

  data: () => ({
    LogoColmedica
  }),

  computed: {
    ...mapState({
      env: state => state.env,
      view: state => state.virtualrowFSFB.view
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  },

  methods: {
    ...mapActions({
      goBack: "virtualrowFSFB/goBack"
    }),

    back(){
      if (this.view == "Messages"){
        return this.$emit("backFromMessages");
      }

      if(this.view != "NoDate"){
        this.goBack();
      } else  {
        this.$emit("backFromNoDate");
      }
      
    }
  }
};
</script>
<style scoped>
.atrilHeader {
  background-color: #001698;
  padding: 1.2rem 1rem;
}

.header {
  text-align: center;
  padding: 1.69rem 2rem;
  /* padding-top: 22px; */
}

.goback,
.imageContainerHeader,
.iconHomeAtril {
  display: inline-block;
}

.goback {
  font-family: "Gilroy-Semibold";
  vertical-align: middle;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
}

.imageContainerHeader {
  width: 94%;
  margin: 0px auto;
}

.client_logo {
  padding-right: 55px;
  max-width: 230px;
  max-height: 58px;
  height: auto;
}
</style>

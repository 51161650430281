<template lang="pug">
.Home.Container2.align-items-flex-start(style="color: #545454; margin: auto; width: 100%;")
  .Content(v-if="false")
    .banderaSvg
    article.w-100
      .d-flex.containerBoxes.w-100.h-100
        .box
          h4.textHeroH4.w-100.mt-5.mb-2.pb-3 Fundación Santa Fe de Bogotá
          .whiteCard.mx-auto
            h3.fonterH3.pt-0.mt-2.text-center ¡Hola, elige tu idioma!
            p.text-center.textHero.w-100.mt-1.mb-4.pb-3 Hi, Select your language
            .px-5.pt-5
              button(
                @click="goCurrent"
              ).button-without-styles.d-flex.buttonContinue.p-2.w-100.mx-auto.mb-4.align-items-center.justify-content-between
                img(src="@/assets/ESP.png" alt="ESP").img-ESP
                span Español
                span.opacity-0 Esp
              button(
                @click="goCurrent"
              ).button-without-styles.d-flex.buttonContinue.p-2.w-100.mx-auto.align-items-center.justify-content-between
                img(src="@/assets/USA.png" alt="USA").img-USA
                span English
                span.opacity-0 Esp
            //- h3.textHero.mb-4.px-4 o toma un turno digital escaneando este código QR con tu celular:
            //- .containerQr.mt-5
            //-   .canvas_bordered
            //-     img(:src="InfoQRView1" alt="InfoQRView1").svg
            //-     .canvas_container
            //-       #qr
        .box.box2.mx-0.w-100
          img(v-if="areaCustom.backgrounds" :src="areaCustom.backgrounds.background1" alt="saludImportantImage").containerImg-img.w-100
          img(src="@/assets/logoFSFBTransparent.png" alt="logoFSFBTransparent").logoFSFBTransparent
          .box-tape.text-center.d-flex.pl-5
            .boxText.text-start.d-block
              .ml-4.pl-3.boxBorderer
                h3.welcomeText.mb-0: strong Bienvenido a Fundación Santa Fé de Bogotá
                h4.welcomeText.welcomeTextSecondary.mb-2: strong (Zona {{areaCustom.label}})
              p.descText.mb-0.pl-5.ml-2.mt-4(v-if="areaCustom && areaCustom.labelEnglish") Welcome to
              p.descText.mb-4.pl-5.ml-2(v-if="areaCustom && areaCustom.labelEnglish") {{areaCustom.labelEnglish}}
        //- .box.box2.px-5
        //-   h3.textHero.mb-3.px-4 Tomar turno con ticket de impresión
        //-   img(:src="InfoQRView" alt="InfoQRView")
        //-   .px-4.w-100
        //-     .px-2.w-100
        //-       button(
        //-         @click="goCurrent"
        //-       ).buttonGreen.mt-3 Continuar
  .Footer
</template>

<script>
import { mapActions, mapState } from "vuex";
// import QrCode from "easyqrcodejs";

import Header from "./components/Header.vue";

import InfoQRView from "@/assets/ticket.png";
import InfoQRView1 from "@/assets/infoQRView1_20.svg";

export default {
  name: "Home",

  data() {
    return {
      qrcode: null,
      text: "",
      changeColor: "",
      changeColorText: "",
      changeColorThree: "",
      InfoQRView,
      InfoQRView1
    };
  },

  components: {
    Header
  },

  mounted() {
    this.goToView({ view: "Home" });
    this.$store.dispatch("turns/cancelTurnAction", true);
    this.text = `${location.origin}/#/fv/${this.$route.params.code}${
      this.$route.query.area ? "?area=" + this.$route.query.area : ""
    }`;
    // eslint-disable-next-line no-unused-vars
    // this.qrcode = new QrCode(document.getElementById("qr"), {
    //   text: this.text,
    //   width: 180,
    //   height: 180,
    //   colorDark: "black",
    //   colorLight: "#FFFFFF"
    // });
  },

  watch: {
    primaryColorBlue() {
      document
        .getElementById("qr")
        .querySelector("canvas")
        ?.remove();
      // this.qrcode = new QrCode(document.getElementById("qr"), {
      //   text: this.text,
      //   width: 180,
      //   height: 180,
      //   colorDark: "black",
      //   colorLight: "#FFFFFF"
      // });
    }
  },

  computed: {
    ...mapState({
      currentSite: state => state.virtualrowFSFB.currentSite,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      sites: state => state.virtualrowFSFB.sites
    }),

    siteName() {
      return this.currentSite?.name;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    areaCustom() {
      try {
        return this.sites[0].customAreas[this.$route.query.area]
      } catch (error) {
        return "";
      }
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrowFSFB/goToView"
    }),

    goCurrent() {
      this.$store.dispatch("virtualrowFSFB/goToView", {
        view: "Home"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.Content {
  position: relative;
  width: 100%;
  height: 100%;
}

.Home {
  height: calc(100vh - 112.06px);
  overflow: auto;
  display: flex;
  justify-content: stretch;
  align-items: start;
}

.Home > div {
  display: table-cell;
  vertical-align: middle;
}

.text-start {
  text-align: start;
}

.p {
  margin: 1rem auto;
  margin-top: 5rem;
  width: 262.12px;
  height: 51px;

  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  /* or 144% */

  text-align: center;

  /* TEXT/Sub títulos, body, íconos. */

  color: #788591;
}

.dk-pos {
  position: fixed;
  top: 0;
  right: 0;
}

.canvas_bordered {
  position: relative;
  .svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -34px;
  }
}

.buttonGreen {
  font-family: "Gilroy";
  width: 100%;
  max-width: 432px;
  height: 88px;
  display: block;
  margin: auto;
  border-radius: 22px;
  font-style: normal;
  font-size: 28px;
  line-height: 25px;
  text-align: center;
  border: none;
  color: #ffffff;
  background: #009dac;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.textHero {
  max-width: 584px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 44px;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  line-height: 31px;
  color: #788591;
}

.canvas_container {
  margin: 0px auto;
  display: flex;
  max-width: 186px;
  justify-content: center;
  background: white;
  padding-top: 0.8rem;
  padding-bottom: 0.1rem;
  #canvas {
    width: 170px !important;
    height: 170px !important;
    object-fit: contain;
  }
}

.dk-pos-dk {
  position: absolute;
  top: -90%;
  left: 0px;
  transition: 1s;
  right: 0px;
  font-weight: bold;
  font-size: 2.8rem;
}

.box {
  padding: 70px 0;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin: 0 1rem;
}

.box.box2 {
  padding: 0;
  max-width: 799px;
}

.buttonContinue {
  max-width: 432px;
  border: 2px solid #dedede;
  border-radius: 22px;
  color: #788591;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.15));
}

.buttonContinue span {
  font-family: "Gilroy-Semibold";
}
.InfoQRView1 {
  margin-bottom: 3rem;
}

.containerQR {
  height: 100%;
  padding: 0.5rem;
}

.containerImg-img {
  object-fit: cover;
  max-height: calc(100vh - 112.06px);
}

.box-tape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    359.49deg,
    #202d78 2.94%,
    rgba(32, 40, 87, 0.661458) 56.73%,
    rgba(33, 30, 23, 0) 99.46%
  );
}

.boxText {
  position: absolute;
  bottom: 163px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  max-width: fit-content;
}

.logoFSFBTransparent {
  position: absolute;
  right: 0;
  bottom: 126px;
  z-index: 2;
}

.moreToLive {
  position: absolute;
  top: 65px;
  right: 67px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  z-index: 3;

  color: #ffffff;
}

.welcomeText {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 55px;
  color: #ffffff;
}

.descText,
.consultasText {
  font-size: 24px;
  color: #ffffff;
}

.descText {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}

.copyrightText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.consultasText {
  max-width: 497px;
}

.whiteCard {
  padding-top: 71px;
  width: 584px;
  height: 544px;
  border-radius: 20px;
  background: #ffffff;
  filter: drop-shadow(0px 5.77255px 11.5451px rgba(21, 21, 21, 0.08));
}

.textHeroH4 {
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #788591;
}

.fontDescCard {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #788591;
}

.boxBorderer {
  border-left: 16px solid #22bdff;
}

.welcomeTextSecondary {
  font-size: 40px;
  line-height: 40px;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>

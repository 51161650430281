<template lang="pug">
div
  .Progress2-modal
    .container-modal
      .progress-modal(:data-percentage="percent" style="cursor: pointer;")
        span.progress-modal-left
          span.progress-modal-bar
        span.progress-modal-right
          span.progress-modal-bar
        .progress-modal-value
          .mt--1
            span
              svg(width='25' height='23' viewbox='0 0 25 23' fill='none' xmlns='http://www.w3.org/2000/svg').colorHouse
                path(d='M23.931 11.0673L13.1887 0.305881C13.1114 0.228188 13.0195 0.16655 12.9184 0.124494C12.8172 0.0824384 12.7088 0.060791 12.5993 0.060791C12.4898 0.060791 12.3814 0.0824384 12.2803 0.124494C12.1791 0.16655 12.0873 0.228188 12.0099 0.305881L1.26764 11.0673C0.954686 11.381 0.777344 11.8072 0.777344 12.2516C0.777344 13.1746 1.52583 13.9249 2.44645 13.9249H3.57831V21.6038C3.57831 22.0666 3.95125 22.4405 4.41287 22.4405H10.9302V16.5839H13.8511V22.4405H20.7858C21.2474 22.4405 21.6203 22.0666 21.6203 21.6038V13.9249H22.7522C23.1955 13.9249 23.6206 13.7498 23.9336 13.4334C24.583 12.7798 24.583 11.7209 23.931 11.0673Z' fill='#96acdd')
</template>

<script>
/* eslint-disable prettier/prettier */
import { mapState, mapActions } from "vuex";

export default {
  name: "ProgressModal2",

  data() {
    return {
      percent: 100,
      seconds: 15,
      timeout: null
    };
  },

  created() {
    // if (this.restart) {
    //   this.$store.dispatch("setTimer");
    // }
    this.seconds = 15;
    this.timerFunction();
  },

  beforeDestroy() {
    clearInterval(this.timeout);
  },

  computed: {
    ...mapState({
      generalTimeoutModal: state => state.generalTimeoutModal,
      view: state => state.virtualrowFSFB.view,
      restart: state => state.restart,
      removeTimeout: state => state.removeTimeout,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      goTime: state => state.goTime
    }),
  },

  watch: {
    generalTimeoutModal() {
      if (this.removeTimeout) return this.percent = 0;
      this.percent = 0;
      setTimeout(() => {
        this.seconds = 15;
        this.percent = 100;
      }, 100);
    },

    // goTime(val) {
    //   if (val) {
    //     this.$store.dispatch("setTimerModal", 60000);
    //   } else {
    //     this.$store.dispatch("setTimerModal", 27000);
    //   }
    // }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrowFSFB/goToView",
    }),
    timerFunction() {
      // const self = this;
      // this.timeout = setInterval(function() {
      //   if (self.percent <= 0) {
      //     clearInterval(self.timeout);
      //   }
      //   if (self.view === "Turn" && self.restart) {
      //     clearInterval(self.timeout);
      //     self.timerFunction();
      //   }
      //   self.seconds -= 1;
      // }, 1000);
    },
    toHome() {
      console.log("PROGRESSMODAL2")
      this.goToView({ view: "QR" });
    },
  }
};
</script>
  
<style lang="scss">
  $borderWidth: 3.5px;
  $animationTime: 40s;
  $border-color-default: #96acdd;
  $border-color-progressbar: #001698;
  $size: 55px;
  $howManySteps: 100; //this needs to be even.
  
  .progress-modal {
    width: $size !important;
    height: $size !important;
    line-height: $size;
    background: none;
    margin: 1px auto;
    box-shadow: none;
    position: relative;
    background-color: white !important;
    .mt--1 {
      margin-top: -3px;
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: $borderWidth solid $border-color-default;
      position: absolute;
      top: 0;
      left: 0;
    }
    > span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .progress-modal-left {
      left: 0;
    }
    .progress-modal-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: $borderWidth;
      border-style: solid;
      position: absolute;
      top: 0;
      border-color: $border-color-progressbar;
    }
    .progress-modal-left .progress-modal-bar {
      left: 100%;
      border-top-right-radius: ($size/2);
      border-bottom-right-radius: ($size/2);
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
      //animation: loading-2 1.5s linear forwards 1.8s;
    }
    .progress-modal-right {
      right: 0;
      .progress-modal-bar {
        left: -100%;
        border-top-left-radius: ($size/2);
        border-bottom-left-radius: ($size/2);
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
        //animation: loading-1 1.8s linear forwards;
      }
    }
    .progress-modal-value {
      display: flex;
      border-radius: 50%;
      font-size: 1rem;
      text-align: center;
      line-height: 20px;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      font-weight: 300;
      span {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  
  .colorHouse {
    fill: #96acdd;
    color: #96acdd;
  }
  
  .colorHouseDanger {
    fill: rgb(240, 64, 64);
    color: rgb(240, 64, 64);
  }
  
  /* This for loop creates the necessary css animation names
  Due to the split circle of progress-left and progress right, we must use the animations on each side.
  */
  @for $i from 1 through $howManySteps {
    $stepName: ($i * (100 / $howManySteps));
  
    //animation only the left side if below 50%
    @if $i <= ($howManySteps/2) {
      .progress-modal[data-percentage="#{$stepName}"] {
        .progress-modal-right .progress-modal-bar {
          animation: loading-#{$i} $animationTime linear forwards;
        }
        .progress-modal-left .progress-modal-bar {
          animation: 0;
        }
      }
    }
    //animation only the right side if above 50%
    @if $i > ($howManySteps/2) {
      .progress-modal[data-percentage="#{$stepName}"] {
        .progress-modal-right .progress-modal-bar {
          // eslint-disable-next-line prettier/prettier
          animation: loading-#{($howManySteps/2)}$animationTime
            linear
            forwards; //set the animation to longest animation
        }
        .progress-modal-left .progress-modal-bar {
          animation: loading-#{$i -
            ($howManySteps/2)}
            $animationTime
            linear
            forwards
            $animationTime;
        }
      }
    }
  }
  
  //animation
  @for $i from 1 through ($howManySteps/2) {
    $degrees: (180/ ($howManySteps/2));
    $degrees: ($degrees * $i);
    @keyframes loading-#{$i} {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate($degrees);
        transform: rotate(#{$degrees}deg);
      }
    }
  }
</style>
    
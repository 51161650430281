<template lang="pug">
.MainView.box_size(:class="{ atril: isAtril }" :style="isAtril && 'overflow-y: hidden;'")#rootVirtualrowFSFB
  Header(
    :class="{'atrilHeader': isAtril }"
    @backFromNoDate="goBackFromNoDate"
    @backFromMessages="goBackFromMessages"
  )
  template
    .version-box(v-if="isAtril")
      small(@click="specialAction") versión {{ version }}
      h6(v-if="customArea" style="text-align: right; font-size: 0.7rem;") Area {{ customArea }}
    div(v-else style="position: fixed; right: 10px; top: 5px;")
      h6(style="text-align: right; font-size: 0.6rem; margin-bottom: 0;") V. {{ version }}
      h6(v-if="customArea" style="text-align: right; font-size: 0.6rem;") A. {{ customArea }}
  LoadingFSFB(v-if="isLoading")
  button(style="display: flex; align-items: center; justify-content: center; height: 60px; width: 60px; position: absolute" v-if="view != 'Turn'"  @click="() => goToView({ view: 'Home' })").containerTimerFSFBVirtualrow
    circular-count-down-timer(
      main-circle-id="circle-1"
      :show-value="false"
      class="CountdownTimerVirtualRowAtrilFsfb"
      @update="onUpdateTimer"
      :circles="circles"
      v-if="isAtril && ((view !== '') || forceNormalProgress) && showCCT")
    img(src="../../assets/houseVirtualrowFSFB.svg" @click="() => goToView({ view: 'Home' })" alt="houseVirtualrowFSFB").houseVirtualrowFSFB
  //- img(src="../../assets/maskFSFB.png" alt="maskFSFB").containerTimerFSFBVirtualrow.houseMask
  CurrentUser(ref="CurrentUser" v-if="view == 'Home'" :onStopTimer="onStopTimer")
  //- ProgressPayment(v-if="view == 'Payment'" ref="ProgressPayment")
  Payment(v-if="view == 'Payment'" 
    @startTimer="startTimerPayments" 
    @stopTimerPayments="stopTimer"
    :stopTimerPayments="stopTimer"
    @restartTimerPayents="restartTimerPay"
    :onStopTimer="onStopTimer"
  )
  Help(v-else-if="view == 'Help'")
  ExistentUser(v-else-if="view == 'ExistentUser'")
  QRView(v-else-if="view == 'QR'")
  OutOperations(
    :style="{ marginTop: isAtril ? '10vh' : '' }"
    v-else-if="view == 'OutOperations'"
    :withOutClose="isAtril"
    :withOutHeader="true"
  )
  Normativity(v-else-if="view == 'evaluationPriority'")
  NormativityBack(v-else-if="view == 'normativityBack'")
  NoDate(ref="NoDate" v-else-if="view == 'NoDate'")
  Messages(ref="Messages" v-else-if="view == 'Messages'")
  Quote(v-else-if="view == 'Quote'")
  ErrorAutenticate(v-else-if="view == 'ErrorAutenticate'")
  ErrorAutenticateVal(v-else-if="view == 'ErrorAutenticateVal'")
  Normativity(v-else-if="view == 'Normativity'")
  Pay(v-else-if="view == 'Pay'")
    template(#default)
      .d-flex(style="align-items: center; justify-content: center;")
        b-button.mt-4(variant="bluecolmedica" :style="colorComputed" @click="toHome")
          span Volver al inicio
  Turn(
    :onStopTimer="onStopTimer"
    :tramiteName="tramiteName"
    v-else-if="view == 'Turn'"
    :userInfo="turnObject"
    :branchId="branch"
    :queueId="tramite"
    :source="source"
    :onlyGenerate="isAtril"
    :withAnalitycExtraFields="true"
    :waitVideoSource="waitVideoSource"
    :waitVideoPoster="waitVideoPoster"
    :hideVideo="hideVideo"
    @generatedTurn="printTicket"
    @endTurn="endTurn"
    @errorRequestTurn="toHome"
    @goBack="goBack"
    @enablePogress="enablePogress"
    @disableProgress="disableProgress"
  )
    template(#waitingActions v-if="isAtril")
      b-button(variant="bluecolmedica" @click="toHome" :style="isAtril ? 'font-size: 1.5rem;' + ' ' + colorComputed : colorComputed")
        span Volver al inicio
    //- TODO: Backend messages no
    //- template(#enqueue  v-if="currentValidation.length")
    //-   template(v-for="valid in currentValidation")
    //-     h5.mt-2.mb-2.text-middle(:style="colorStyle") {{ valid.DescripcionPaso }}
    template(#waiting="{ turn }")
      template(v-if="currentValidation.length")
        h4.mt-4.text-middle(v-for="(valid, i) in currentValidation" :key="i") {{ valid.DescripcionPaso }}
      h4.mt-2.text-middle(
        v-if="!currentValidation.length"
      )
        div(v-if="!siteIsOffice")
          p.m-0.fontAssignedText
            | Tu turno asignado es
        p(v-else).m-0.fontAssignedTextValue
          | Tu turno asignado es
      h4.mt-4.text-middle(
        v-if="currentValidation.length"
      ).fontAssignedText tu turno asignado es
      h3.text-center.color-colmedica-light.mt-0.fontAssignedTextValue {{ turn.myTurn }}
      p.m-0.mt-5: small.estimadoP Tiempo estimado de espera
      .mb-3.circleEstimado.mt-3.mx-auto
        h2 {{ calculatedMinutes(turnInf.jsonDetails.averageWaitingTime) }}
        h4 Minuto{{ calculatedMinutes(turnInf.jsonDetails.averageWaitingTime) === 1 ? "" : "s" }}
      .impresoTicket.pt-4
        p.mb-0 Toma el ticket impreso
        svg.mb-0(width='24' height='11' viewbox='0 0 24 11' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M12 11L0.74167 0.5L23.2583 0.500002L12 11Z' fill='#283645')
    //-   h5.mt-5.text-middle(:style="isAtril ? 'font-size: 2rem; color: #545454; font-weight: bold;' : 'margin-top: 1rem !important; font-weight: bold !important; color: #545454;'") Próximo turno:
    //-   h5.text-middle.color-colmedica-light.font-weight-bold(:style="inAttentionStyle") {{ turn.actualTurn }}
    //-   h5.text-middle(:style="isAtril ? 'color: #545454; font-size: 2rem;' : ' font-weight: bold !important; color: #545454; margin-top: 1rem !important;'").mt-5 {{isAtril ? "Favor toma el turno impreso" : ""}}
    //-   h5.text-middle(:style="isAtril ? 'color: #545454; font-size: 2rem;' : ' font-weight: bold !important; color: #545454;'").mt-0 {{isAtril ? "y procede a la sala de espera" : ""}}
    //- template(#endInfo)
    //-   b-button.mt-4(variant="bluecolmedica" @click="toHome" :style="isAtril ? 'font-weigth: 100 !important; font-size: 1.5rem; margin: auto; display: block;' + ' ' + colorComputed : 'margin: auto; display: block;' + ' ' + colorComputed")
    //-     span(:style="isAtril ? 'font-weigth: 100 !important;' : ''") Volver al inicio
  .pending(v-else-if="view != 'Home'") Estamos verificando la sucursal.
</template>

<script>
// FIXME: Arreglar el footer, preguntar a Marcelo por si el footer no aparece, igual lo ponemos en estructura html?
import { mapActions, mapState } from "vuex";
import { getBranch } from "@/helpers/Meet";

// views
import CurrentUser from "./CurrentUser";
import BranchTimeValidation from "./BranchTimeValidation";
import OutOperations from "../OutOperations";
import NoDate from "./NoDate";
import Messages from "./Messages.vue";
import Quote from "./Quote";
import Normativity from "./Normativity";
import ErrorAutenticateVal from "../autoservice/ErrorAutenticateVal";
import ErrorAutenticate from "./ErrorAutenticate.vue";
import Help from "./Help.vue";
import Turn from "./components/Turn";
import Pay from "./Pay";
import Header from "./components/Header";
import QRView from "./QRView";
import ExistentUser from "./ExistentUser";
import NormativityBack from "./NormativityBack.vue";
import Payment from "./Payment.vue";
// import EvaluationPriority from "./EvaluationPriority.vue";
// import Progress from "./components/Progress";
// import Progress2 from "./components/Progress2";
// import ProgressPayment from "./components/ProgressPayment.vue";
import LoadingFSFB from "./components/LoadingFSFB.vue";

import moment from "moment";
import onScan from "onscan.js";
import LS from "../../helpers/localStorage";
import $ from "jquery";

export default {
  name: "virtualrowFSFBAtril",

  data: () => ({
    lastUpdate: null,
    timerReset: false,
    resetCounter: 0,
    timerCounter: null,
    intervalBranch: null,
    interval: null,
    isLoading: false,
    generatedTurnStatus: false,
    forceNormalProgress: false,
    company: null,
    seconds: 0,
    secondsSchedules: 0,
    limitTimer: 14,
    circles: [
      {
        id: "circle-1",
        size: 60,
        stepLength: 1,
        steps: 15,
        classList: "circle-1",
        fillColor: "#001698",
        strokeColor: "#5ac3F0",
        strokeWidth: 3.5
      }
    ],
    showCCT: true
  }),

  components: {
    CurrentUser,
    OutOperations,
    BranchTimeValidation,
    NoDate,
    Messages,
    // ProgressPayment,
    Quote,
    ErrorAutenticate,
    Normativity,
    ErrorAutenticateVal,
    Payment,
    Help,
    Turn,
    Pay,
    // Progress,
    Header,
    QRView,
    NormativityBack,
    ExistentUser,
    LoadingFSFB
    // Progress2
    // EvaluationPriority
  },

  created() {
    this.setCustomArea(this.$route.query.area);
    this.setSede(this.$route.params.code);
    window.document.title = "Turno virtual";
    if (location.hash.includes("fv_atril"))
      this.$store.commit("virtualrowFSFB/setState", {
        key: "isAtril",
        value: true
      });
    this.setBranchInfo(null);
    console.log("this.isAtril: " + this.isAtril);
    if (this.history.length === 0) this.BranchRequest(true);
    if (this.isAtril) {
      this.intervalBranch = setInterval(() => this.BranchRequest(), 1000);
      this.callAlive();

      console.log("Scanner");

      let vue = this;

      $(document).keydown(function(e) {
        // var key = e.Code || e.keyCode;
        // enter key do nothing
        e.preventDefault();
        if (!vue.isLoading && vue.view == "Home") {
          vue.isLoading = true;
          let reset = setTimeout(() => {
            vue.isLoading = false;
            clearTimeout(reset);
          }, 2000);
        }
      });

      /* eslint-disable */

      // Initialize with options
      onScan.attachTo(document, {
          suffixKeyCodes: [13], // enter-key expected at the end of a scan
          reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
          onScan: function(sCode, iQty) { // Alternative to document.addEventListener('scan')
            console.log("sCode")
            console.log(sCode)
            if(sCode){
              vue.isLoading = true;
              var re = /[0-9]{10}[A-Z]{3}/g;
              let ced = re.exec(sCode);
              console.log(ced)
              if(ced){
                LS.setItem("scanCed", parseInt(ced[0]))
                // vue.$store.dispatch("virtualrowFSFB/goToView", {
                //   view: "Home"
                // });
                vue.$refs.CurrentUser.models.NumeroIdentificacion = parseInt(ced[0]);
                vue.$refs.CurrentUser.sendData();
              }
              vue.isLoading = false;
            }

          },
          // onKeyDetect: function(iKeyCode){ // output all potentially relevant key events - great for debugging!
          //     console.log('Pressed: ' + iKeyCode);
          // }
      });      
      
      /* eslint-enable */
    }
  },

  mounted() {
    var events = ["mouseup", "keydown", "scroll", "mousemove"];
    let vue = this;

    document.body.addEventListener("click", this.onClickBody);

    // Check events
    events.forEach(function(e) {
      document.addEventListener(e, function() {
        // date last action
        vue.setUserActionDate(Date.now());
      });
    });
  },

  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalBranch);
  },

  watch: {
    $route(val) {
      this.setCustomArea(val?.query.area);
      if (val?.params.code)
        this.getMenus({
          code: val?.params.code,
          area: val?.query.area
        });
    },
    branchInfo(val) {
      if (val) this.firstViewCheck();
    },
    eventTime() {
      this.$store.dispatch("virtualrowFSFB/goToView", { view: "QR" });
    },
    view(val, oldVal) {
      console.log(val);
      if (val) {
        this.$store.commit("virtualrowFSFB/setState", {
          key: "stopTimerCircle",
          value: false
        });
      }
      try {
        if (
          this.isAtril &&
          val === "QR" &&
          oldVal &&
          window.lastUpdate &&
          this.lastUpdate &&
          moment(this.lastUpdate).diff(moment(window.lastUpdate), "seconds") > 0
        )
          location.reload();
        // eslint-disable-next-line no-empty
      } catch (error) {}
      if (val === "Pay" && this.isAtril) {
        this.printPay(this.currentValidation);
      }
      if (this.reset) {
        this.timerReset = true;
        // this.$store.dispatch("setTimer", true);
      }
      // if (val == "QR") {
      //   setTimeout(() => {
      //     // Sure this is atril
      //     let origin = "fv_";
      //     if (location.hash.includes("fv_atril")) origin = "fv_atril_fsfb_";

      //     // tracking
      //     let trackingData = {
      //       uuid: window.person.id,
      //       accion: "Customer - Inicio",
      //       debmedia_turn_code: "",
      //       url_origen: window.location.href,
      //       origen: origin + this.currentSite?.type,
      //       mensajes: ""
      //     };

      //     this.sendTracking(trackingData);
      //   }, 1000);
      // }
    },

    currentValidation(val) {
      if (val && val.length >= 1) {
        let data = {
          name: this.currentUser.Nombres,
          tipide_dni: this.currentUser.TipoIdentificacion,
          numide_dni: this.currentUser.NumeroIdentificacion,
          first_message: val[0]?.DescripcionPaso,
          second_message: val[1]?.DescripcionPaso,
          code_id: this.currentUser.CodigoOficina,
          branch_id: this.currentUser.branch_id || "0",
          origin: this.isAtril ? "Atril" : "Web"
        };
        this.saveRecord(data);

        try {
          // tracking
          let trackingData = {
            uuid: window.person.id,
            accion: "a-consultorio",
            debmedia_turn_code: "",
            url_origen: window.location.href,
            origen: this.isAtril ? "Atril" : "Web",
            mensajes: val[0]?.DescripcionPaso + " - " + val[1]?.DescripcionPaso
          };
          this.sendTracking(trackingData);
        } catch (error) {
          // tracking
          let trackingData = {
            uuid: window ? window.person?.id : "None",
            accion: "error-a-consultorio",
            debmedia_turn_code: "",
            url_origen: window ? window.location?.href : "None",
            origen: this.isAtril ? "Atril" : "Web",
            mensajes: error
          };
          this.sendTracking(trackingData);
        }
      }
    }
  },

  computed: {
    ...mapState({
      branchInfo: state => state.branchInfo,
      view: state => state.virtualrowFSFB.view,
      history: state => state.virtualrowFSFB.history,
      sites: state => state.virtualrowFSFB.sites,
      currentSite: state => state.virtualrowFSFB.currentSite,
      customArea: state => state.virtualrowFSFB.customArea,
      actions: state => state.virtualrowFSFB.actions,
      currentUser: state => state.virtualrowFSFB.currentUser,
      currentValidation: state => state.virtualrowFSFB.currentValidation,
      priority: state => state.virtualrowFSFB.priority,
      sessionMaster: state => state.sessionMaster,
      isAtril: state => state.virtualrowFSFB.isAtril,
      selectedAccion: state => state.virtualrowFSFB.selectedAccion,
      eventTime: state => state.eventTime,
      reset: state => state.reset,
      restart: state => state.restart,
      version: state => state.version,
      env: state => state.env,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      dclient_data: state => state.dclient_data,
      turnInf: state => state.turns.info,
      userActionDate: state => state.virtualrow.userActionDate,
      stopTimerCircle: state => state.virtualrowFSFB.stopTimerCircle
    }),

    colorStyle() {
      const style = this.isAtril
        ? {
            "font-size": "2rem",
            color: `#545454 !important`,
            "font-weight": "bold"
          }
        : {
            "font-weight": "bold !important",
            color: "#545454"
          };
      return style;
    },

    turnStyle() {
      const style = this.isAtril
        ? `font-size: 2.5rem; font-weight: bold; color: ${this.primaryColorBlue}`
        : `font-weight: bold !important; color: ${this.primaryColorBlue}; font-size: 2.3rem;`;
      return style;
    },

    inAttentionStyle() {
      const style = this.isAtril
        ? `font-size: 2rem; font-weight: bold; color: ${this.primaryColorBlue};`
        : `font-weight: bold !important; color: ${this.primaryColorBlue};`;
      return style;
    },

    waitVideoSource() {
      return (
        this.selectedAccion?.waiting?.video || this.currentSite?.waiting?.video
      );
    },

    waitVideoPoster() {
      return (
        this.selectedAccion?.waiting?.poster ||
        this.currentSite?.waiting?.poster
      );
    },

    evaluateCompany() {
      return this.env.VUE_APP_EVALUATE_COMPANY === "true";
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    hideVideo() {
      return (
        this.selectedAccion?.waiting?.hide ||
        this.currentSite?.waiting?.hide ||
        false
      );
    },

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    prodmode() {
      return this.env.VUE_APP_ENV === "production";
    },

    letterCapPos() {
      return Number(this.env.VUE_APP_LETTERCAP_POS) || 40;
    },

    comp() {
      return this.env.VUE_APP_COMPANY;
    },

    defaultBranchId() {
      return this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH;
    },

    turnObject() {
      // const value = !!this.selectedTramite?.priority_tramite_id;
      let lastName = (this.currentUser?.apellido || this.currentUser?.last_name || "") +
        " " +
        (this.currentUser?.segundo_apellido ||
          this.currentUser?.mother_last_name ||
          "");
      return {
        cedulaIdentidad: this.sessionMaster,
        cuil: this.sessionMaster,
        cuit: this.currentUser?.TipoIdentificacion,
        dni: this.currentUser?.numero_documento || this.currentUser?.doc || this.currentUser?.NumeroIdentificacion,
        firstName: this.currentUser?.nombre || this.currentUser?.names || "",
        lastName: lastName == ' ' ? '' : lastName,
        phone: this.currentUser?.celular ?? ""
        // hasPriority: !value && this.priority
      };
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    branch() {
      return this.currentSite?.branch_id
        ? `${this.currentSite.branch_id}`
        : undefined;
    },

    selectedTramite() {
      return (
        this.actions?.[this.customArea] ||
        this.actions?.default ||
        []
      ).find(br => br.actionCode === this.selectedAccion?.actionCode);
    },

    tramite() {
      return this.selectedTramite?.tramite_id
        ? `${(this.priority && this.selectedTramite?.priority_tramite_id) ||
            this.selectedTramite?.tramite_id}`
        : undefined;
    },

    tramiteName() {
      return (
        this.selectedAccion?.printLabel || this.selectedAccion?.label || ""
      );
    },

    source() {
      return this.isAtril
        ? `fv_atril_fsfb_${this.currentSite?.type ?? ""}/`
        : `fv_${this.currentSite?.type ?? ""}/`;
    }
  },

  methods: {
    ...mapActions({
      setCustomArea: "virtualrowFSFB/setCustomArea",
      getSites: "virtualrowFSFB/getSites",
      getStyles: "virtualrowFSFB/getStyles",
      getMenus: "virtualrowFSFB/getMenus",
      goToView: "virtualrowFSFB/goToView",
      cancelTurnAction: "turns/cancelTurnAction",
      goBack: "virtualrowFSFB/goBack",
      setBranchInfo: "setBranchInfo",
      getCompany: "getCompany",
      saveRecord: "virtualrowFSFB/handleSendRecords",
      sendTracking: "virtualrowFSFB/handleTracking",
      setSede: "virtualrowFSFB/setSede",
      setUserActionDate: "virtualrow/setUserActionDate"
    }),

    onClickBody() {
      this.showCCT = false;
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        this.showCCT = true;
      }, 3);
    },

    onStopTimer() {
      const interval = setInterval(() => {
        this.showCCT = false;

        if (
          this.view === "Quote" ||
          this.view === "ExistentUser" ||
          this.view === "Help" ||
          this.stopTimerCircle
        ) {
          clearInterval(interval);
          this.showCCT = true;
        }
      }, 3);
    },

    async BranchRequest(first = false) {
      // Check view
      if (this.view && this.view != "Home" && this.view != "OutOperations")
        return;

      // // Just if app is in use
      // let diff = moment(Date.now()).diff(
      //   moment(this.userActionDate),
      //   "seconds"
      // );
      // if (diff < 60 * 10) { // 10 minutes
      this.seconds++;
      if (first || this.seconds == this.env.VUE_APP_POLLING_FREQUENCY) {
        this.seconds = 0;
        this.company = await this.getCompany(this.comp);
        let lastUpdate = this.company?._global_?.lastUpdate;
        if (lastUpdate && this.lastUpdate !== lastUpdate)
          this.lastUpdate = lastUpdate;
        await this.getSites();
        await this.getStyles();
        const finded = this.sites.find(
          site => site.code == this.$route.params.code
        );
        if (finded) {
          this.$store.commit("virtualrowFSFB/setState", {
            key: "currentSite",
            value: finded
          });
        }
        await this.getMenus({
          code: this.$route.params.code,
          area: this.$route.query.area
        });
      }

      // Check schedules
      this.secondsSchedules++;
      if (first || this.secondsSchedules == 10) {
        this.secondsSchedules = 0;
        let branchFiltered = this.branch
          ? Object.keys(this.company).find(
              name => this.company[name].branch_id == this.branch
            )
          : undefined;
        if (!this.company) return;
        if (!branchFiltered)
          branchFiltered = Object.keys(this.company).find(
            name => this.company[name].branch_id == this.defaultBranchId
          );
        this.setBranchInfo(await getBranch(this.comp, branchFiltered));
      }
    },

    calculatedMinutes(turn) {
      return Math.ceil(turn);
      // return "15" || turn?.myTurn;
    },

    callAlive() {
      if (typeof window.alive === "function") {
        this.interval = setInterval(() => {
          window.alive(true);
        }, 1000);
      } else console.log("Is'nt alive");
    },

    async firstViewCheck() {
      if (this.branchInfo) {
        if (this.isAtril) {
          if (
            (this.view === "QR" || this.view === "") &&
            !this.branchInfo.activeBranch
          ) {
            await this.goToView({ view: "OutOperations" });
            // this.removeTimer();
          } else if (
            (this.view === "OutOperations" || this.view === "") &&
            this.branchInfo.activeBranch
          ) {
            this.goToView({ view: "QR" });
          }
        } else {
          if (
            (this.view === "Home" || this.view === "") &&
            !this.branchInfo.activeBranch
          ) {
            await this.goToView({ view: "OutOperations" });
            // this.removeTimer();
          } else if (
            (this.view === "OutOperations" || this.view === "") &&
            this.branchInfo.activeBranch
          ) {
            this.goToView({ view: "Home" });
          }
        }
      }
    },

    toHome() {
      if (this.isAtril) this.goToView({ view: this.isAtril ? "QR" : "Home" });
      else location.reload();
    },

    async endTurn() {
      this.generatedTurnStatus = false;
      this.onStopTimer();
      // if (this.isAtril) await this.$store.dispatch("setTimer", true);
    },

    normalizer(str) {
      return typeof str === "string"
        ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        : str;
    },

    async printPay(list) {
      let posPrinterInfo = [
        {
          text: "",
          size: {
            width: 0,
            height: 0
          },
          style: "n",
          align: "ct"
        }
      ];
      for (const elm of list) {
        let listWords = elm.DescripcionPaso.split(" ");
        if (listWords.length <= 1)
          posPrinterInfo.push({ text: elm.DescripcionPaso });
        else {
          let letterCap = this.letterCapPos;
          let index = 0;
          let currentLine = [];
          let lines = [];
          while (listWords[index] !== undefined) {
            if (letterCap >= listWords[index].length) {
              letterCap -= listWords[index].length;
              letterCap -= 1; // Remove space
              currentLine.push(listWords[index]);
              index++;
            } else {
              lines.push(currentLine);
              currentLine = [];
              letterCap = this.letterCapPos;
            }
          }
          if (currentLine.length) lines.push(currentLine);
          for (let i = 0; i < lines.length; i++) {
            const lin = lines[i];
            posPrinterInfo.push({ text: lin.join(" ") });
          }
        }
        posPrinterInfo.push({ text: "" });
      }
      posPrinterInfo = [
        ...posPrinterInfo,
        { text: "" },
        {
          text: moment().format("YYYY-MM-DD hh:mm a"),
          size: {
            width: 0,
            height: 0
          }
        },
        { text: "" },
        { text: "" },
        { text: "" }
      ];
      let url = `${location.origin}/#/fv_print_pay?${list.reduce(
        (prev, curr) =>
          prev + "ev=" + encodeURIComponent(curr.DescripcionPaso) + "&",
        ""
      )}`;
      if (typeof window.ElectronPrinter === "function") {
        try {
          await window.ElectronPrinter({
            mode: "printURL",
            url,
            optsPrinter: {
              silent: true,
              printBackground: false,
              color: false,
              margin: {
                marginType: "printableArea"
              },
              landscape: false
            },
            posPrinter: {
              posPrinterInfo,
              posPrinterSetup: this.currentSite?.posPrinterSetup
            }
          });
        } catch (error) {
          console.error("Error when print ticket", error);
        }
      } else console.log("ElectronPrinter not exist");
    },

    async printTicket(info) {
      this.forceNormalProgress = false;
      this.generatedTurnStatus = true;
      this.onStopTimer();
      // if (this.isAtril) await this.$store.dispatch("setTimer", true);
      let turn = info.turnInfo?.jsonDetails.turn ?? "NA";
      let tramite = this.tramiteName;
      let branch = info.turnInfo?.branch?.label;
      let queue = info.turnInfo?.jsonDetails.queue?.name ?? "NA";
      let posPrinterInfo = [
        { text: "" },
        {
          text: this.normalizer(turn),
          size: {
            width: 2,
            height: 2
          },
          style: "bu",
          align: "ct"
        },
        { text: "" },
        {
          text: this.normalizer(tramite),
          size: {
            width: 0,
            height: 1
          },
          style: "n"
        },
        { text: "" },
        {
          text: this.normalizer(branch),
          size: {
            width: 0,
            height: 0
          }
        },
        { text: this.normalizer(queue) },
        { text: "" },
        { text: moment().format("YYYY-MM-DD hh:mm a") },
        { text: "" },
        { text: "" },
        { text: "" }
      ];
      let url = `${
        location.origin
      }/#/fv_print?turn=${turn}&tramite=${encodeURIComponent(
        tramite
      )}&branch=${encodeURIComponent(branch)}&queue=${encodeURIComponent(
        queue
      )}`;
      console.log("To print", url);
      if (typeof window.ElectronPrinter === "function") {
        try {
          await window.ElectronPrinter({
            mode: "printURL",
            url,
            // opts: {
            //   height: 400,
            //   width: 350
            // },
            optsPrinter: {
              silent: true,
              printBackground: false,
              color: false,
              margin: {
                marginType: "printableArea"
              },
              landscape: false
              // scaleFactor: 0.5
              // pageRanges: [{ from: 0, to: 0 }]
              // pageSize: {
              //   height: 500
              // },
              // pagesPerSheet: 1,
              // collate: false
            },
            posPrinter: {
              posPrinterInfo,
              posPrinterSetup: this.currentSite?.posPrinterSetup
            }
          });
          this.generatedTurnStatus = false;
        } catch (error) {
          this.generatedTurnStatus = false;
          console.error("Error when print ticket", error);
        }
      } else {
        this.generatedTurnStatus = false;
        console.log("ElectronPrinter not exist");
      }
    },

    onUpdateTimer(event) {
      if (event["circle-1"] === this.limitTimer) {
        this.goToView({ view: "Home" });
      }
    },

    specialAction() {
      if (!this.isAtril) return;
      this.resetCounter++;
      if (this.timerCounter) clearTimeout(this.timerCounter);
      if (this.resetCounter > 4) {
        this.resetCounter = 0;
        location.reload();
      } else {
        this.timerCounter = setTimeout(() => {
          this.resetCounter = 0;
          this.timerCounter = null;
        }, 2000);
      }
    },

    enablePogress() {
      this.forceNormalProgress = true;
    },

    disableProgress() {
      this.forceNormalProgress = false;
    },

    goBackFromNoDate() {
      if (this.$refs.NoDate) this.$refs.NoDate.BackCalculed();
    },

    goBackFromMessages() {
      if (this.$refs.Messages) this.$refs.Messages.BackMessage();
    },

    startTimerPayments() {
      this.$refs.ProgressPayment.timerFunction();
    },

    stopTimer() {
      if(this.$refs.ProgressPayment) this.$refs.ProgressPayment.stopTimer();
    },

    restartTimerPay(time) {
      this.showCCT = false;
      setTimeout(() => {
        this.showCCT = true;
      }, 1000);
      this.limitTimer = parseInt(time - 1);
      this.circles[0].steps = time;
      // this.$refs.ProgressPayment.restart();
    }
  }
};
</script>

<style lang="scss" scoped>
.Container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box_size {
  max-width: 100vw;
  margin: 0px auto;
  margin-bottom: 10px; /*35*/
  font-family: Helvetica-light;
  font-size: 1.2rem;
  font-weight: bold;
  color: #545454;
}

.pending {
  text-align: center;
  margin-top: 2rem;
}

.impresoTicket p {
  font-family: "Gilroy" !important;
}

.impresoTicket {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 23px;
  text-align: center;
  color: #788591;
}

.dk-pos {
  position: absolute;
  top: -12px;
  display: flex;
  left: calc(100vw - 14rem);
  justify-content: flex-end;
  width: fit-content;
}

.box_size.atril {
  margin: 0px auto;
  /* max-width: 900px; */
  max-width: 100%;
  width: 100%;
  margin-bottom: 0;
  font-size: 1.7rem !important;
  font-weight: bold;
  color: #545454;
}

.circle__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box_size.atril * {
  /* font-weight: bold !important; */
  font-family: "Source Sans Pro", sans-serif;
}

.box_size.atril .btn {
  font-size: 1.7rem !important;
}

.box_size.atril .h1 {
  font-size: 2.7rem;
  font-weight: bold !important;
}

.dk-pos-dk {
  position: absolute;
  top: -67%;
  left: 0px;
  right: 0px;
}

.dk-overlay {
  background-color: rgb(255 255 255 / 82%);
  position: relative;
  padding: 1rem;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
}

.buttons {
  display: none !important;
}

.estimadoP {
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}

.circleEstimado {
  width: 185px;
  height: 185px;
  background: #4b5cb7;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  padding-top: 1rem;
}

.circleEstimado h2 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 84px;
  text-align: center;
  color: #ffffff;
}

.circleEstimado h4 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
}

.fontAssignedText {
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  color: #788591;
}

.fontAssignedTextValue {
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  color: #009dac;
}

.version-box {
  position: fixed;
  right: 15px;
  bottom: 85px;
  z-index: 12;
}

.circle-1 {
  background-color: #009dac;
}

// .houseMask {
//   z-index: 8;
//   right: 34px;
//   top: 21px;
//   padding: 0 !important;
// }

@media only screen and (min-width: 999px) {
  .box_size {
    max-width: 500px;
  }
}

@media screen and (max-height: 720px) {
  .dk-pos-dk {
    top: 22%;
  }
}
</style>

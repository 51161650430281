<template lang="pug">
.Principal
  .panel
    slot
</template>

<script>
export default {
  name: "Panel"
};
</script>

<style>
.Principal {
  max-width: 500px;
  display: block;
  /* margin: auto; */
  margin-top: 10px;
}

.panel {
  border-radius: 5px;
  display: grid;
  font-weight: bold;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  gap: 4.8px;
}

.item-panel {
  padding: 1.2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 35.1391px;
  line-height: 35px;
  cursor: pointer;
  text-align: center;
  color: #f2f4f8;
}

.item-panel.bigbutton {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 35.1391px;
  line-height: 35px;

  border-radius: 6px;
  color: #202d78;
  background: none;
  box-shadow: 0px 1.40556px 0px rgba(0, 0, 0, 0.25);
}

.item-panel:last-of-type {
  background-color: #1a73e9;
  color: white;
}

.item-panel.item-panel-delete_button {
  background: none;
  color: #202d78;
  border: none;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
}
/*
.item-panel:last-of-type:hover {
  background-color: #014579;
} */
</style>

<template lang="pug">
.Principal
  .panel
    slot
</template>

<script>
export default {
  name: "PanelAlpha"
};
</script>

<style lang="scss" scoped>
.panel {
  grid-template-columns: repeat(7, 1fr);
}

.item-panel:last-of-type {
  background: none;
  color: #202d78;
}
</style>

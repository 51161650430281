<template lang="pug">
div(:class="fv_atril_video ? 'lds-dual-ring-fv' : 'lds-dual-ring'")
</template>

<script>
export default {
  name: "Spinner",
  props: ["fv_atril_video"]
};
</script>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #aaaaaa;
  border-color: #aaaaaa transparent #aaaaaa transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring-fv {
  display: inline-block;
  width: 120px;
  height: 120px;
}

.lds-dual-ring-fv:after {
  content: " ";
  display: block;
  width: 90px;
  height: 90px;
  margin: 8px;
  border-radius: 50%;
  border: 15px solid #aaaaaa;
  border-color: #aaaaaa transparent #aaaaaa transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template lang="pug">
.Help.Container(:style="isAtril ? 'color: #545454;' : 'color: #545454; font-size: 1.3rem;'")
  .banderaSvg
  .Content
    .CardWhite.mx-auto
      .pb-0.pt-0
        .box-dk.mt-0.mb-0.px-5.d-flex.justify-content-start.pb-1.container-header
          img(src="@/assets/cancel.png" alt="infoSvgIcon").infoSvgIcon.mb-5.mt-0
          h4.textHero.mb-0.ml-4.text-start
            | Bienvenido, eres un usuario nuevo
      .mb-2.pb-3.mt-0.px-5
        p.mb-0.text-start
          strong.boldLabel Documento:
          span.ml-3.textFonter {{ optionSelected.text || "No registra"}}
        p.text-start.fonterNumberContainer
          strong.boldLabel.boldNumber Numero:
          span.pl-3.textFonter.mb-3 {{ currentUser ? currentUser.NumeroIdentificacion : "" }}
    .mt-2
      button(
        @click="goUserView"
      ).text-center.buttonService.mx-auto.d-block
        span
          | Continuar
      button(
        @click="onGoToCurrentUser"
      ).text-center.outlinedServiceButton.mr-4.mt-0.button-without-styles
        span
          | Reingresar datos
</template>

<script>
import { mapActions, mapState } from "vuex";

import HelpIcon from "mdi-vue/HelpCircle.vue";
import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";

export default {
  name: "Help",

  watch: {
    currentUser(val) {
      if (!val) this.goBack();
    }
  },

  components: {
    Header,
    HelpIcon,
    Continue,
    MiniSpinner
  },

  // created() {
  //   if (this.isAtril) this.$store.dispatch("setTimer");
  // },

  computed: {
    ...mapState({
      currentUser: state => state.virtualrowFSFB.currentUser,
      charging: state => state.virtualrowFSFB.charging,
      currentSite: state => state.virtualrowFSFB.currentSite,
      isAtril: state => state.virtualrowFSFB.isAtril,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      secondColorBlue: state => state.virtualrowFSFB.secondColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      options: state => state.virtualrowFSFB.tipide
    }),
    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },
    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },
    siteIsOffice() {
      return this.currentSite?.type === "of";
    },
    siteName() {
      return this.currentSite?.name;
    },
    optionSelected() {
      return this.options.find(
        option => option.value === this.currentUser.TipoIdentificacion
      );
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrowFSFB/goToView",
      goBack: "virtualrowFSFB/goBack",
      cleanCurrentUser: "virtualrowFSFB/cleanCurrentUser"
    }),

    onGoToCurrentUser() {
      this.goToView({ view: "Home" });
    },

    cleanAndBack() {
      // if (this.isAtril) this.$store.dispatch("setTimer");
      this.cleanCurrentUser();
      this.goBack();
    },

    goUserView() {
      // if (this.isAtril) this.$store.dispatch("setTimer");
      this.goToView({ view: "NoDate" });
    }
  }
};
</script>

<style scoped>
.Help {
  max-width: 100%;
  height: calc(100vh - 112.06px);
  overflow: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Help > div {
  display: table-cell;
  vertical-align: middle;
}

.gray {
  color: gray;
}

.big_Informative {
  position: relative;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.dk-font {
  font-size: 1.3rem;
}

.dk-pos-dk {
  position: absolute;
  top: -45%;
  left: 0px;
  transition: 1s;
  right: 0px;
  font-size: 2.8rem;
}

.boxInformative {
  width: 584px;
  text-align: start;
  margin: auto;
  display: block;
  padding: 2.5rem;

  background: #202d78;
  border-radius: 20px;
}

.boxInformative p {
  margin-bottom: 1rem;
}

.boxInformative p span,
.boxInformative p strong {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.boxInformative p span {
  font-family: "Gilroy";
  font-weight: 400;
}

.boxInformative p:last-child {
  margin-bottom: 0;
}

.boxInformative > p {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 25px;
  /* or 144% */

  /* RESPUESTAS AND ACCENT */

  color: #081e3d;
}

.buttonService {
  font-family: "Gilroy-Semibold";
  width: 100%;
  max-width: 497px;
  padding: 1.5rem 2rem;
  margin-top: 48px;
  margin-bottom: 45px;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.buttonService:disabled {
  background-color: #788591;
}

.buttonService.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.outlinedServiceButton span {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: #009dac;
}

.infoSvgIcon {
  width: 67.06px;
  height: 71px;
}

.CardWhite {
  margin-top: -90px;
  width: 735px;
  height: 296px;
  border-radius: 20px;
  filter: drop-shadow(0px 5.77255px 11.5451px rgba(21, 21, 21, 0.08));
  background: #ffffff;
}

.fonter {
  width: 510px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #69cdfe;
}

.textHero {
  max-width: 584px;
  font-style: normal;
  font-size: 48px;
  font-weight: 800;
  line-height: 55px;
  color: #788591;
}

.container-header {
  padding-top: 71px;
}

.textFonter {
  font-weight: 400;
  font-size: 26px;
  line-height: 29.98px;
  color: #788591;
  height: 32px;
}

.boldLabel {
  font-weight: 800;
  font-size: 26px;
  line-height: 31.85px;
  height: 32px;
  color: #788591;
}

.boldNumber {
  margin-left: 45px;
}

.text-start {
  text-align: start;
}

.fonterNumberContainer {
  margin-top: -10px;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
